// APP

@mixin work-order-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .work-order-detail-card-span {
    overflow: auto;
    max-height: calc(100vh - 112px);

    .work-order-detail-card {
      border-radius: 0px;
      padding: 0px;
      margin: 16px;

      ng-bee-worker-static-data.work-object-resp-tech {
        .worker-chip {
          max-width: 96%;
        }
      }

      // TOP HEADER
      .new_detail-card-header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-title-holder {
          display: flex;
          align-items: center;

          .mat-mdc-card-avatar {
            background-color: var(--primary-color-500);
            color: #fff;
            padding: 5px;
            display: grid;
            align-items: center;
            text-align: center;
          }

          h2 {
            margin: 0 0 0 10px;
          }
        }

        .new_detail-actions-wrapper {
          white-space: nowrap;
          button {
            margin-left: 10px;
            min-height: 38px;
            mat-icon {
              margin-right: 0 !important;
            }
          }
          .delete-button {
            margin-right: 0;
          }
        }
      }

      // BODY DATA
      .work-order-detail-card-body {
        padding: $padding__page;
        display: flex;

        ng-bee-w-h-agm-core-location-map.work-order-detail-w-h-agm-core-location-map
          .location-map {
          agm-map {
            height: 246px;
          }
        }

        .work-order-assignment-placeholder {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          // border: 1px dashed mat.get-color-from-palette($primary, 200);
          // background-color: mat.get-color-from-palette($primary, 200);
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: mat.get-color-from-palette($primary, 50);
        }

        .column-flex-min-width {
          flex: 33%;
          min-width: 33%;
        }

        .work-order-summary-label-row {
          margin-top: 8px;
        }

        .work-order-summary-wrapper {
          overflow-x: hidden;
          border: 1px solid transparent;
          height: 100%;
          width: 100%;

          > span {
            height: 100%;
          }

          mat-chip {
            cursor: pointer;
          }
        }
      }

      .w-h-default-button.work-order-button {
        margin-right: $padding__page;
      }

      // .mat-tab-body-content {
      //   min-height: 244px;
      //   height: 244px;
      //   overflow: hidden;
      // }

      .row-wrapper.work-order-detail-wrapper {
        padding: $padding__page $padding__page 0 0;
        margin-bottom: 0;
      }
    }
  }

  .assignment-span {
    background-color: #fff;
    .mat-tab-body {
      overflow: hidden;
      height: calc(100vh - 162px);
      overflow-x: hidden;
      .mat-tab-body-content {
        height: 100%;
        overflow: hidden;
        // padding: 16px 24px 16px 16px;
      }
    }
    .mat-tab-label {
      height: 48px;
      padding: 0px;
      cursor: pointer;
      box-sizing: border-box;
      opacity: 1;
      min-width: 0px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      position: relative;
      background-color: #fff;
      color: mat.get-color-from-palette($primary, 400);
    }

    .mat-tab-label-active {
      // background-color: mat.get-color-from-palette($accent, 500);
      color: #fff;
    }
    .mat-tab-label-active-selected {
      // background-color: mat.get-color-from-palette($accent, 500);
      color: #fff;
    }
  }
}

::ng-deep {
  .mat-mdc-tab-body-content {
    overflow: hidden !important;
  }
}
